import React from 'react';

import Figure from 'react-bootstrap/Figure';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import './furwalk.css';

const RoundSection = ({id, title, children}) => (
    <Container as="section" id={id}>
        <Row><h2>{title}</h2></Row>
        <Row><hr /></Row>
        <Row>{children}</Row>
    </Container>
);

function FurwalkOld() {
    
    return (
        <Container>
            <header id="main">
                <h1>Łódzki Furwalk</h1>
                <img alt="Logo Furwalku" src="img/logo.png" />
            </header>
        
            <RoundSection id="intro" title="Furwalk w Łodzi">
                <p>Nasza impreza odbędzie się w dniu 27 kwietnia 2024 roku. <br /> Przechadzkę zaczynamy i kończymy w <a href="https://memo.lodz.pl/">Mediatece MeMo</a> (ul. Moniuszki 5, 90-101 Łódź).</p>
                <p>Odbiór identyfikatorów: od 10:00</p>
                <p>Wymarsz: 11:00</p>
                <p>Powrót: około 13:00</p>
                <p>After-piwo: od 16:00</p>
                <p>Osoby chcące skorzystać z przebieralni proszone są o wyrobienie karty bibliotecznej <br /> (zajmuje to tylko kilka minut).</p>
                <p>After odbędzie się w <a href="https://browarwarkot.pl/klubopiwiarnia/">klubokawiarnii WARKOT</a> (Narutowicza 7/9, 90-117 Łódź). <br /> Zalecamy codzienny strój, bo nie mamy sali na wyłączność</p>
            </RoundSection>

            <RoundSection id="trasa" title="Trasa Łódzkiego Furwalku">
                <Figure>
                    <Figure.Image src="img/mapka.png" alt="Trasa marszu" />
                </Figure>
            </RoundSection>
            
            <RoundSection id="reg" title="Regulamin">
                <p><small>Ogłoszony w dniu 25.03.2024. Ostatnia modyfikacja 02.04.2024.</small></p>
                <h3>Postanowienia ogólne</h3>
                <ol>
                    <li>Głównym organizatorem wydarzenia "Łódzki Furwalk" odbywającego się w dniu 27 kwietnia 2024 w Łodzi jest osoba o nicku XKaterinX.</li>
                    <li>Organizatorzy oraz osoby ich wspomagające (zwane dalej helperami) będą podczas wydarzenia nosić kamizelki odblaskowe.</li>
                    <li>Udział w wydarzeniu jest bezpłatny.</li>
                    <li>Wszyscy uczestnicy futrzastego spaceru zobowiązani są do dbania o bezpieczeństwo swoje oraz osób w ich bezpośrednim otoczeniu.</li>
                    <li>Zakaz wszelkich działań sprzecznych z prawem, agresywnych, obraźliwych lub dyskryminujących zarówno wobec innych uczestników spaceru jak i osób postronnych.</li>
                    <li>Należy unikać sytuacji zagrażających zdrowiu i dobremu samopoczuciu innych, a w szczególności:
                        <ul>
                            <li>wbiegania przed spacerujących fursuiterów,</li>
                            <li>używania otwartego ognia w bliskości fursuiterów,</li>
                            <li>wszelkich form fizycznego lub psychicznego nękania uczestników,</li>
                            <li>używania niecenzuralnego języka (także w ramach odtwarzanej muzyki) oraz innych zachowań nieodpowiednich dla młodszych uczestników,</li>
                            <li>przynoszenia broni, noży i innych przedmiotów niebezpiecznych a także atrap imitujących wygląd takowych.</li>
                        </ul>
                    </li>
                    <li>Organizatorzy nie ponoszą odpowiedzialności za szkody na majątku ani zdrowiu uczestników, nie powstałe z ich bezpośredniej winy.</li>
                    <li>Organizatorzy ani personel Mediateki MeMo nie odpowiadają za rzeczy zagubione lub pozostawione bez opieki.</li>
                    <li>Osoby niepełnoletnie muszą pozostawać pod pieczą opiekuna prawnego lub posiadać jego pisemną zgodę na samodzielny udział w tym wydarzeniu (<a href="zgoda.pdf" download="formularz zgody.pdf">wzór do pobrania</a>).</li>
                    <li>Biorąc udział w wydarzeniu zgadzasz się na uwiecznienie Twojego wizerunku na zdjęciach i filmach.</li>
                    <li>Biorąc udział w wydarzeniu akceptujesz niniejszy regulamin i zobowiązujesz się do jego przestrzegania.</li>
                </ol>
                
                <h3>Zachowanie na trasie</h3>
                <ol start="12">
                    <li>Podczas spaceru uczestnicy proszeni są o:
                        <ul>
                            <li>trzymanie się wyznaczonej trasy spaceru,</li>
                            <li>unikanie niepotrzebnego zakłócania ruchu pojazdów i pieszych,</li>
                            <li>dostosowanie swojej prędkości do otoczenia,</li>
                            <li>stosowanie się do próśb i poleceń helperów oraz organizatorów.</li>
                        </ul>
                    </li>
                    <li>Uczestnicy zobowiązani są do utrzymania porządku i sprzątania po sobie. Nie wolno zostawiać śmieci czy innych przedmiotów na trasie spaceru.</li>
                    <li>Uczestnicy ze zwierzętami ponoszą za nie pełną odpowiedzialność. Zwierzaki nie mogą wbiegać przed fursuiterów ani brudzić lub uszkadzać ich strojów. Zwierzęta mniejsze można nieść na rękach, a te większe należy prowadzić na smyczy. Jeśli podopieczny nabrudzi, należy po nim posprzątać.</li>
                    <li>Organizatorzy nie zapewniają muzyki na czas przemarszu. Wszelka muzyka towarzysząca nam podczas spaceru pochodzi z inicjatywy jego uczestników i jest odtwarzana na ich użytek osobisty.</li>
                </ol>            
    
                <h3>Zasady ubioru</h3>
                <ol start="16">
                    <li>Uczestnicy spaceru powinni nosić stroje:
                        <ul>
                            <li>wykonane całkowicie lub w części z dowolnego rodzaju futra, lub</li>
                            <li>zwykłą codzienną odzież.</li>
                        </ul>
                    </li>
                    <li>Ubiór uczestników nie może godzić w zasady dobrego obyczaju, a w szczególności:
                        <ul>
                            <li>zawierać znaków i symboli stosowanych przez obecne bądź historyczne reżimy totalitarne,</li>
                            <li>propagować nazizmu, faszyzmu bądź komunizmu,</li>
                            <li>zawierać elementów fetyszowych (jak np. obroże z ćwiekami, pup-maski, gumowe lub połyskliwe “body”),</li>
                            <li>nadmiernie odsłaniać ani podkreślać budowy ciała,</li>
                            <li>zawierać referencji religijnych.</li>
                        </ul>
                    </li>
                    <li>Osoby, których strój nie będzie spełniał wymogów przedstawionych w regulaminie mogą nie zostać dopuszczone do udziału w wydarzeniu.</li>
                </ol>
            </RoundSection>
    
            <RoundSection id="socjale" title="Nasze społecznościówki">
                <p>Aby być na bieżąco śledźcie nasz <a href="https://t.me/furwalk_lodz">Kanał&nbsp;na&nbsp;Telegramie&nbsp;<img src="img/telegram.png" alt="Telegram" width="24" height="24" /></a>.</p>
                <p>Jeśli chcecie lepiej się poznać, możecie także dołączyć do <a href="https://t.me/furwalk_lodz_grupa">Grupy&nbsp;Uczestników&nbsp;<img src="img/telegram.png" alt="Telegram" width="24" height="24" /></a> Łódzkiego Furwalku.</p>
            </RoundSection>

            <footer>
                <p>Wszystkie prawa zastrzeżone, 2024</p>
            </footer>
        </Container>
    );
}


export default function Furwalk() {
    
    return (
        <Container>
            <header id="main">
                <h1>Łódzki Furwalk</h1>
                <img alt="Logo Furwalku" src="img/logo.png" />
            </header>
        
            <RoundSection id="outro" title="Podziękowania">
                <p>Drogie Futerka i Przyjaciele, <br />
                dziękujemy Wam za tak liczne przybycie i za Wasze wzorowe zachowanie na trasie! Było nam bardzo miło się z Wami przechadzać.
                Bardzo serdecznie dziękujemy naszym uczynnym Helperom, w składzie: Azzy, G4cek, Mefi, Nikita i Noxxy.
                Jesteśmy bardzo wdzięczni za wszelką okazaną nam pomoc w organizacji tego wydarzenia, w szczególności Setiemu, Darrnokowi oraz zespołowi <a href="https://memo.lodz.pl/">Mediateki MeMo</a>.
                Zapraszamy Was od regularnego odwiedzania tej biblioteki.</p>

                <p>Pozdrawiamy,<br />
                Kasia, Olek, Limes i Zoraris</p>        
        
                <p><a href="https://t.me/furwalk_lodz">Kanał Furwalku na Telegramie&nbsp;<img src="img/telegram.png" alt="Telegram" width="24" height="24" /></a>.</p>

            </RoundSection>

            <RoundSection id="photo" title="Zdjęcia">
                <p>Wasze kolekcje zdjęć z Łódzkiego Furwalku:</p>
                <p> <a href="https://drive.google.com/drive/folders/1-lf1zXYUlUwCJT8DW_gjW0vWckrBGiy0" target="_blank" rel="noopener noreferrer">Azzy</a>,&nbsp;
                    <a href="https://drive.google.com/drive/folders/11sIwT4i3eXvoJb-JAsCI_UXdpTF6tdPE" target="_blank" rel="noopener noreferrer">G4cek</a>,&nbsp;
                    <a href="https://drive.google.com/drive/folders/1h7hgAgo0g4r7QBl2mU9KdFlmcToMq1J1" target="_blank" rel="noopener noreferrer">irxmm</a>,&nbsp;
                    <a href="https://drive.google.com/drive/folders/1h-u33SWu1N5Lf9DRUoJVkjprgy0FImAM" target="_blank" rel="noopener noreferrer">Sinek</a>,&nbsp;
                    <a href="https://t.me/TechTheFoxFoto" target="_blank" rel="noopener noreferrer">TechTheFox&nbsp;(<img src="img/telegram.png" alt="Telegram" width="24" height="24" />)</a>,&nbsp;
                    <a href="https://photos.app.goo.gl/MhdSCLWorpYWVXBg8" target="_blank" rel="noopener noreferrer">Bonuś</a>,&nbsp;
                    <a href="https://photos.app.goo.gl/dE7Nn7nEuSFia9DJ9" target="_blank" rel="noopener noreferrer">ANZA (efutro.pl)</a>,&nbsp;
                    <a href="https://photos.app.goo.gl/F95qgFohHazzo6Xy6" target="_blank" rel="noopener noreferrer">Georg</a>,&nbsp;
                    <a href="https://photos.app.goo.gl/dQd4cR3dmdFo7gwq6" target="_blank" rel="noopener noreferrer">Zoraris</a>.
                </p>
            </RoundSection>
        
            <footer>
                <p>Wszystkie prawa zastrzeżone, 2024</p>
            </footer>
        </Container>
    );
}
